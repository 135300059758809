import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import VisibilitySensor from 'react-visibility-sensor'
import { Element } from 'react-scroll'
import { useWindowSize, useMount } from 'react-use'

import { Layout, Block, DiningForm } from '../components'
import { container, padding, noPadding, colours } from '../styles/global'
import { parseACF } from '../utils'
import { media, useBreakpoint } from '../styles/utils'

const Functions = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

    const footer_text = '<p>Mo<span>nday</span> – We<span>dnesday</span> 3pm – 10pm<br />Th<span>ursday</span> – Sa<span>turday</span> 12pm – 11pm<br />Su<span>nday</span> 12pm – 10pm</p><p>35 Station St. Malvern 3144<br /><span aria-label="Call phone number (03) 9500 0218">(03) 9500 0218</span></p><p><a href="mailto:hello@essiewine.com" target="_blank" rel="noopener">Email</a> <a href="https://www.instagram.com/essie_wine/" target="_blank" rel="noopener">Instagram</a></p>'

    const renderPageBuilder = () => {
        if(!data?.page_builder) return

        const items = data.page_builder.map((block, index) => {

            if (block.acf_fc_layout == 'pagebuilder_carousel') {
                return (
                    <Block
                        layout={'slider'}
                        display
                        slides={block?.pagebuilder_carousel}
                        key={index}
                        inlineCaption={true}
                    />
                )
            }

            if (block.acf_fc_layout == 'pagebuilder_text') {
               return (
                    <Block
                        layout={'text'}
                        display
                        serif
                        paragraph={block?.pagebuilder_text_content}
                        key={index}
                        styles={TextStyles}
                    />
               )
            }

            if (block.acf_fc_layout == 'pagebuilder_button') {

                return (
                    <Block
                        layout={'button'}
                        display
                        link={block?.pagebuilder_button_link}
                        text={block?.pagebuilder_button_text}
                        removePadding={block?.pagebuilder_remove_padding}
                        key={index}
                    />
                )
            }
        })


        return (
            <Container>
                {items}
            </Container>
        )
    }


    return (
        <Layout meta={data && data.seo} footer={footer_text}>
            {renderPageBuilder()}
        </Layout>
    )
}

// Shared

const Wrapper = styled.div``
const Text = styled.div``
const Button = styled.div``
const Section = styled.section``
const Image = styled(ProgressiveImage)``
const InlineImage = styled.img``
const Meta = styled.div``
const Credits = styled.div``

// Layout

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
	overflow: hidden;
    padding-top: 200px;

    ${media.tablet`
        padding-top: 100px;
    `}

    .block_slider {
        ${media.tablet`
            padding-left: 0;
            padding-right: 0;
        `}
    }
`

const TextStyles = css`
    padding: 0;

    .text {
        padding: 100px 0;

        ${media.tablet`
            padding: 70px 0;
        `}
    }
`


// Data

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "functions" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Functions